// Core
import { useCallback, useState } from 'react';
// Packages
import moment from 'moment';
// Redux
import { getShiftsForNotifyAsync } from 'redux/schedule/scheduleThunk';
import { STORE_KEYS, useAppDispatch, useAppSelector } from 'redux/store';
// Helpers
import { DATE_FORMAT_STANDARD, ROLES } from 'helpers/data/constants';
// Interfaces and types
import { TGetNotNotifyShiftItem } from 'types/scheduleTypes';

// Get shifts for notify employees
const useNotifyEmployees = () => {
  const { user } = useAppSelector(STORE_KEYS.USER);

  const dispatch = useAppDispatch();

  const [isShiftsLoading, setIsShiftsLoading] = useState(false);
  const [shiftsForNotify, setShiftsForNotify] = useState<TGetNotNotifyShiftItem[] | null>(
    null,
  );

  const dateFrom = moment().format(DATE_FORMAT_STANDARD);

  const getShiftsFormNotify = useCallback(async () => {
    if (user?.role === ROLES.business_company) {
      return null;
    }

    setIsShiftsLoading(true);

    try {
      const data = await dispatch(getShiftsForNotifyAsync({ dateFrom }));

      if (getShiftsForNotifyAsync.fulfilled.match(data)) {
        setShiftsForNotify(
          data.payload?.items?.filter(({ employeeStatus }) => employeeStatus === 'active'),
        );
      }
    } finally {
      return setIsShiftsLoading(false);
    }
  }, [dateFrom, dispatch, user?.role]);

  return { getShiftsFormNotify, isShiftsLoading, shiftsForNotify };
};

export default useNotifyEmployees;
